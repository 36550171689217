import React, { Component } from 'react'
import styled from 'styled-components'

const StyledMenu = styled.div`
  font-family: Calibri, sans-serif !important;
  font-size: 18px;
  line-height: 120%;
  position: fixed;
  top: 15px;
  left: 28px;
  border: 1px solid #000;
  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  z-index: 4000;
  box-sizing: border-box;

  width: auto;
  max-width: ${props => props.closed ? "58px" : "500px"};
  transition: max-width 700ms ease-in-out;

  ul {
    list-style: none;
    display: inline;
    margin: 0;
    padding: 0;
  }

  ul li.logo {
    width: 58px;
    cursor: alias;
  }

  ul li.logo a {
    cursor: alias;
  }

  ul li {
    cursor: pointer;
    padding: 8px 10px;
    list-style: none;
    display: inline-block;
  }

  @media (max-width: 700px) {
    left: 18px;

    ul li {
      padding: 8px 8px;
    }

    .closed {
      width: 56px;
    }
  }

  ul li.link-item:hover {
    background-color: #000;
  }

  ul li.link-item:hover a {
    color: #fff;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  a:hover {
    border-bottom: none;
  }
`

class PanMenu extends Component {

  constructor() {
    super()
    this.state = {closed: false}
  }

  componentDidMount() {
    setTimeout(() => {this.setState({closed: true})}, 3000)
  }

  mouseEnter() {
    this.setState({closed: false})
  }

  mouseLeave() {
    this.setState({closed: true})
  }


  render() {
    return (
      <StyledMenu closed={this.state.closed} onMouseEnter={()=>this.mouseEnter()} onMouseLeave={()=>this.mouseLeave()}>
        <ul className="logo-menu">
          <li className="link-item clickable" data-route="/">
            <a className="no-link" href="http://delinear.p-a-n.org/">
              <span>P</span><span>A</span><span>N</span>
            </a>
          </li>
          <li className="link-item">
            <a href="http://p-a-n.org/releases/">
              Releases
            </a>
          </li>
          <li className="link-item">
            <a href="http://p-a-n.org/shop/">
              Shop
            </a>
          </li>
          <li className="link-item">
            <a href="http://pan-x-ica.com" target="_blank" rel="noopener noreferrer">
              Events
            </a>
          </li>
          <li className="link-item">
            <a href="http://p-a-n.org/info/">
              Info
            </a>
          </li>
        </ul>
      </StyledMenu>
    )
  }
}

export default PanMenu
