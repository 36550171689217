/* Api methods to call /functions */

const getStreamCredits = (projectName, promoCode) => {
  return fetch(`/.netlify/functions/stream-credits/${projectName}/${promoCode}`, {
    method: 'GET'
  }).then(response => {
    return response.json()
  })
}

const redeemCredit = (projectName, promoCode) => {
  return fetch(`/.netlify/functions/redeem-credit/${projectName}/${promoCode}`, {
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

export default {
  getStreamCredits: getStreamCredits,
  redeemCredit: redeemCredit
}
