import React from 'react';
import PanMenu from './components/pan-menu';
import App from './PlayerApp';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import kuroArtwork from './img/kuro-ost-artwork.jpg';
import kuroLogo from './img/kuro-logo.png';
import nimiiaCetiiArtwork from './img/nimiia-cetii-artwork.jpg';
import nimiiaCetiiLogo from './img/nimiia-cetii-logo.png';
import faustArtwork from './img/faust-artwork.jpg';
import faustLogo from './img/faust.svg';
import iothgArtwork from './img/iothg-artwork.jpg';
import sexArtwork from './img/sex-artwork.jpg';
import tissuesArtwork from './img/tissues-artwork.jpg';
import panLogo from './img/pan-logo.png';

function Kuro() {
  return (
    <App
      projectName="kuro"
      bkgImage={kuroArtwork}
      bkgFilter="blur(5px)"
      logoImg={kuroLogo}
      purchaseLinkText="Purchase Kuro (OST) via PAN"
      purchaseLink="https://p-a-n.org/product/tujiko-noriko-kuro-ost-pan-89/"
      vimeoId="331986024"
      title="Kuro (OST)"
    >
      In conjunction with the release of Kuro (OST), all vinyl purchases of the
      album come with the ability to stream the original film here in full.
      Subtitles available in English, German, French, Italian, Dutch,
      Portuguese, and Japanese.
    </App>
  );
}

function NimiiaCetii() {
  return (
    <App
      projectName="nimiiacetii"
      bkgImage={nimiiaCetiiArtwork}
      logoImg={nimiiaCetiiLogo}
      purchaseLinkText="Purchase nimiia vibié via PAN"
      purchaseLink="https://p-a-n.org/product/jenna-sutela-nimiia-vibie-pan104/"
      vimeoId="353004382"
      title="nimiia vibié"
    >
      In conjunction with the release of Jenna Sutela's nimiia vibié, all vinyl
      purchases of the album come with the ability to stream a related short
      film, nimiia cétiï, here in full.
    </App>
  );
}

function Faust() {
  return (
    <App
      projectName="faust"
      bkgImage={faustArtwork}
      bkgFilter="blur(7px)"
      logoImg={faustLogo}
      purchaseLinkText="Purchase Faust via PAN"
      purchaseLink="https://p-a-n.org/product/anne-imhof-faust-pan-98/"
      vimeoId="359743944"
      title="Faust"
    >
      In conjunction with the release of Anne Imhof’s Faust, all vinyl purchases
      of the album come with the ability to stream video documentation of the
      original performance at the 57th International Art Exhibition of La
      Biennale di Venezia.
    </App>
  );
}

function Iothg() {
  return (
    <App
      projectName="iothg"
      bkgImage={iothgArtwork}
      bkgFilter="blur(7px)"
      logoImg={panLogo}
      purchaseLinkText="Purchase Island of the Hungry Ghosts via PAN"
      purchaseLink="https://p-a-n.org/product/aaron-cupples-island-of-the-hungry-ghosts-ost/"
      vimeoId="495150812"
      title="Island of the Hungry Ghosts"
    >
      In conjunction with the release of Island of the Hungry Ghosts (OST), all
      vinyl purchases of the album come with the ability to stream the original
      film here in full.
    </App>
  );
}

function Sex() {
  return (
    <App
      projectName="sex"
      bkgImage={sexArtwork}
      bkgFilter="blur(7px)"
      logoImg={panLogo}
      purchaseLinkText="Purchase SEX via PAN"
      purchaseLink="https://p-a-n.org/product/anne-imhof-eliza-douglas-billy-bultheel-sex/"
      vimeoId="582657081"
      title="SEX"
    >
      In conjunction with the release of SEX by Anne Imhof, Eliza Douglas and
      Billy Bultheel all vinyl purchases of the album come with the ability to
      stream an exclusive video documentation of their performance at the Tate
      Modern in March 2019.
    </App>
  );
}

function Tissues() {
  return (
    <App
      projectName="tissues"
      bkgImage={tissuesArtwork}
      bkgFilter="blur(7px)"
      logoImg={panLogo}
      invertLogos={true}
      purchaseLinkText="Purchase Tissues via PAN"
      purchaseLink="https://p-a-n.org/product/pan-daijing-tissues-pan-118/"
      vimeoId="703668521"
      title="Tissues"
    >
      In conjunction with the release of Tissues by Pan Daijing all vinyl
      purchases of the album come with the ability to stream an exclusive video
      documentation of their performance at the Tate Modern in autumn 2019.
    </App>
  );
}

function Leonora() {
  return (
    <App
      projectName="leonora"
      bkgFilter="blur(7px)"
      logoImg={panLogo}
      invertLogos={false}
      purchaseLinkText="Purchase The Story of Leonora via PAN"
      purchaseLink="https://p-a-n.org/product/toxe-leonora-pan-127/"
      vimeoId="720283995"
      title="The Story of Leonora (OST)"
    >
      In conjunction with the music release of The Story of Leonora (OST) by
      Toxe all puzzle purchases of the album come with the ability to stream the
      exclusive film 'The Story of Leonora'.
    </App>
  );
}

function AppRouter() {
  return (
    <div>
      <PanMenu />
      <Router>
        <Switch>
          <Route path="/" exact component={Leonora} />
          <Route path="/kuro" component={Kuro} />
          <Route path="/nimiiacetii" component={NimiiaCetii} />
          <Route path="/faust" component={Faust} />
          <Route path="/iothg" component={Iothg} />
          <Route path="/sex" component={Sex} />
          <Route path="/tissues" component={Tissues} />
          <Route path="/leonora" component={Leonora} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </div>
  );
}

export default AppRouter;
