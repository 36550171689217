import React, { Component } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import entopiaLogo from './img/entopia.png';
import api from './utils/api';

const PlayerContainer = styled.div`
  background-color: rgba(30, 30, 30, 0.65);
  max-width: calc(100vw - 40px);
  width: 640px;
  min-height: 360px;
`;

const AppContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

const AppBackground = styled.div`
  background-size: cover;
  position: absolute;
  z-index: -2;
  background-image: url('${(props) => props.bkgImage}');
  background-position: center;
  width: 120vw;
  height: 120vh;
  filter: ${(props) => props.bkgFilter};
`;

const AuthScreen = styled.div`
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  form {
    margin-top: 10px;
  }

  input {
    margin-top: 5px;
    font-size: 18px;
    border: 1px black solid;
    height: 30px;
  }

  input[type='text'] {
    font-size: 18px;
    font-family: courier;
    border: 1px black solid;
    height: 30px;
    width: 120px;
    padding: 10px;
    box-sizing: border-box;
  }

  input[type='submit'] {
    width: auto;
    margin-left: 5px;
    padding: 0 5px 0 5px;
    background: white;
    color: black;
    border: 1px black solid;
    cursor: pointer;
    box-sizing: border-box;
  }

  input[type='submit']:hover {
    color: #fff;
    background-color: #000;
  }

  div {
    color: white;
    padding: 20px;
    text-align: left;
    line-height: 1.5em;
  }

  a {
    color: #ddd;
  }
  a:hover {
    text-decoration: none;
  }
`;

const Logos = styled.div`
  width: 100%;

  position: absolute;
  bottom: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  img {
    height: 50px;
  }
`;

const AuthStatus = styled.div`
  padding-left: 1px !important;
  padding-top: 15px !important;
  height: 10px;
`;

const AuthSuccess = styled(AuthStatus)`
  span {
    font-size: 0.9em;
    color: #090;
    margin: 0;
  }

  a {
    color: #0b0;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AuthFailed = styled(AuthStatus)`
  span {
    font-size: 0.9em;
    color: red;
    margin: 0;
  }
`;

class App extends Component {
  constructor() {
    super();
    this.state = {
      authorized: false,
      promoCode: '',
      invalidOrExpiredCode: false,
      streamCredits: null,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleAuthSubmit = this.handleAuthSubmit.bind(this);
    this.enablePlayer = this.enablePlayer.bind(this);
  }

  handleInput(event) {
    this.setState({ promoCode: event.target.value });
  }

  handleAuthSubmit(event) {
    event.preventDefault();
    api
      .getStreamCredits(this.props.projectName, this.state.promoCode)
      .then((response) => {
        console.log(response);
        if (response.authorized && response.credits > 0) {
          return this.setState({
            invalidOrExpiredCode: false,
            authorized: true,
            streamCredits: response.credits,
          });
        } else {
          return this.setState({ invalidOrExpiredCode: true });
        }
      });
  }

  renderAuthStatus() {
    if (this.state.invalidOrExpiredCode) {
      return (
        <AuthFailed>
          <span>
            Invalid or expired code, please check that you entered correctly.
          </span>
        </AuthFailed>
      );
    } else if (this.state.authorized) {
      return (
        <AuthSuccess>
          <span>
            Code verified. You have {this.state.streamCredits} streaming credits
            remaining. Click{' '}
            <a href="#watch" onClick={this.enablePlayer}>
              here
            </a>{' '}
            to watch now.
          </span>
        </AuthSuccess>
      );
    } else {
      return <AuthStatus />;
    }
  }

  enablePlayer() {
    api
      .redeemCredit(this.props.projectName, this.state.promoCode)
      .then(() => this.setState({ playerEnabled: true }));
  }

  renderAuthScreen() {
    return (
      <>
        {this.props.title && (
          <Helmet>
            <title>{this.props.title} - Entopia</title>
          </Helmet>
        )}
        <AuthScreen>
          <div>
            {this.props.children}
            <br />
            <br />
            Please use your Bandcamp download code for access, as found on the
            DL code card provided with your purchase.
            <br />
            <br />
            Enter your code below:
            <form onSubmit={this.handleAuthSubmit}>
              <input
                maxLength="9"
                type="text"
                placeholder="abcd-1234"
                value={this.state.promoCode}
                onChange={this.handleInput}
              />
              <input type="submit" value="Redeem" />
            </form>
            {this.renderAuthStatus()}
          </div>
          <div>
            →{' '}
            <a href={this.props.purchaseLink}>{this.props.purchaseLinkText}</a>
          </div>
        </AuthScreen>
      </>
    );
  }

  renderPlayerOrAuth(vimeoPlayerId) {
    if (this.state.playerEnabled) {
      return (
        <iframe
          title="Stream Film"
          src={
            'https://player.vimeo.com/video/' +
            vimeoPlayerId +
            '?byline=0&portrait=0&transparent=1'
          }
          width="100%"
          height="360"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      return this.renderAuthScreen();
    }
  }

  render() {
    return (
      <AppContainer>
        <AppBackground
          bkgImage={this.props.bkgImage}
          bkgFilter={this.props.bkgFilter}
        />
        <Logos>
          <img
            style={this.props.invertLogos ? { filter: 'invert(1)' } : null}
            alt="ENTOPIA"
            src={entopiaLogo}
          />
          <img
            style={this.props.invertLogos ? { filter: 'invert(1)' } : null}
            alt="PAN"
            src={this.props.logoImg}
          />
        </Logos>
        <PlayerContainer>
          {this.renderPlayerOrAuth(this.props.vimeoId)}
        </PlayerContainer>
      </AppContainer>
    );
  }
}

export default App;
